<template>
  <v-snackbar-queue v-model="queue">
    <template v-slot:actions="{ item, props }">
      <v-btn
        v-if="item.closable"
        :color="item?.timer"
        icon="mdi-close"
        size="small"
        v-bind="props"
      />
    </template>
  </v-snackbar-queue>
</template>

<script lang="ts" setup>
const { queue } = useSnackbar();
</script>
