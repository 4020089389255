import { createSharedComposable } from "@vueuse/core";

const typeColors: { [key: string]: { color1: string; color2: string } } = {
  default: {
    color1: "black",
    color2: "white",
  },
  success: {
    color1: "green",
    color2: "white",
  },
  error: {
    color1: "red",
    color2: "white",
  },
  warning: {
    color1: "yellow",
    color2: "black",
  },
  info: {
    color1: "blue",
    color2: "white",
  },
};

const _useSnackbar = () => {
  const queue = ref(<any[]>[]);

  const add = (props: any) => {
    const colors: any = typeColors?.[props?.type || "default"];

    props.color = colors.color1;

    if (props?.timer === true) {
      props.timer = colors.color2;
    }

    queue.value.push(props);
  };

  const done = (text: string, props?: any) => {
    add({ ...props, type: "success", text });
  };

  const success = (text: string, props?: any) => {
    add({ ...(props || {}), type: "success", text });
  };

  const warning = (text: string, props?: any) => {
    add({ ...(props || {}), type: "warning", text });
  };

  const error = (text: string, props?: any) => {
    add({ ...(props || {}), type: "error", text });
  };

  const info = (text: string, props?: any) => {
    add({ ...(props || {}), type: "info", text });
  };

  return {
    queue,
    add,
    info,
    done,
    success,
    warning,
    error,
  };
};

export const useSnackbar = createSharedComposable(_useSnackbar);
