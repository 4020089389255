<template>
  <v-app :theme="isDark ? 'dark' : 'light'">
    <NuxtLayout />

    <ConfirmDialog v-model="isOpen" v-bind="props" />
    <SnackbarQueue />
  </v-app>
</template>

<script setup>
import ConfirmDialog from "@/components/common/ConfimDialog.vue";
import SnackbarQueue from "@/components/common/SnackbarQueue.vue";

const { isDark } = useCustomTheme();
const { props, isOpen } = useConfirmDialog();
</script>
