import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as registerPJjcn4pY50Meta } from "/opt/buildhome/repo/pages/auth/register.vue?macro=true";
import { default as _91hash_93i0y7NPwbGAMeta } from "/opt/buildhome/repo/pages/auth/reset-password/[hash].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerPJjcn4pY50Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-hash",
    path: "/auth/reset-password/:hash()",
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password/[hash].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  }
]